import { CompanyDetails, IGlobalState } from './types';

const initialGlobalState = (): IGlobalState => ({
  error: null,
  loading: false,
  uitheme: localStorage.getItem('theme') || 'dark',
  userData: {
    publisherId: '',
    lawFirmName: '',
    userFirstName: '',
    userLastName: '',
    email: '',
    companyDetails: initialCompanyDetails,
  },
  currentPageName: '',
  numberOfElem: 0,
});

const initialCompanyDetails: CompanyDetails = {
  id: '',
  addressLine1: '',
  postalCode: '',
  city: '',
  country: '',
  companyName: '',
  reference: [],
};

export default initialGlobalState;
