import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Card, Spin, Empty } from 'antd';

interface PieChartProps {
  title: string;
  isCosts: boolean;
  data: any;
  loading: boolean;
}

const PieChart = ({ title, isCosts, data, loading }: PieChartProps) => {
  const aggregateData = (data: any[]) => {
    return data.reduce((acc, item) => {
      const existing = acc.find((i: any) => i.groupName === item.groupName);
      if (existing) {
        existing.count += item.count;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, [] as { groupName: string; count: number }[]);
  };

  const transformResponseToCosts = (response: any) => {
    return Object.keys(response).map((key) => ({
      groupName: response[key].jurisdiction,
      count: response[key].amount,
    }));
  };

  const aggregatedData = isCosts
    ? transformResponseToCosts(data)
    : aggregateData(data);

  const customTooltip = ({ datum }: any) => (
    <div
      style={{
        padding: '5px 10px',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '3px',
        color: '#000',
      }}
    >
      {datum.id}: {isCosts ? `EUR ${datum.value}` : `${datum.value} cases`}
    </div>
  );

  return (
    <Card
      title={title}
      style={{ width: '500px', height: 'auto' }}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          height: '400px',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Spin size='large' />
        ) : Object.keys(data).length === 0 ? (
          <Empty description='No data to display' />
        ) : (
          <ResponsivePie
            data={aggregatedData.map((item: any) => ({
              id: item.groupName,
              label: item.groupName,
              value: item.count,
            }))}
            colors={{ scheme: 'set2' }}
            // colors={{ scheme: isCosts ? 'paired' : 'nivo' }}
            margin={{ top: 40, right: -115, bottom: 40, left: 10 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            arcLabelsRadiusOffset={1.2}
            arcLinkLabelsSkipAngle={0}
            arcLinkLabelsTextColor='transparent'
            arcLinkLabelsThickness={0}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]],
            }}
            tooltip={customTooltip}
            legends={[
              {
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                translateX: -10,
                translateY: 50,
                itemsSpacing: 5,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 15,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000',
                    },
                  },
                ],
              },
            ]}
            theme={{
              legends: {
                text: {
                  fontSize: 12,
                },
              },
            }}
          />
        )}
      </div>
    </Card>
  );
};

export default PieChart;
