import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  PaginationProps,
  Spin,
  notification,
  theme,
  Pagination,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import {
  IPropertyCard,
  IPropertySingleCard,
} from '../../store/ducks/IProperty/types';
import SearchComponent from '../../components/Search';
import {
  setIPCardsData,
  setFilteredIPCardsData,
  setSearchTerm,
  setCurrentPage,
  setItemsPerPage,
  searchIPropertyData,
  setSelectedPropertyData,
  setImageURLs,
} from '../../store/ducks/IProperty/operations';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { useNavigate, useLocation } from 'react-router-dom';
import StyledCard from '../../components/StyledCard';
import { StyledSelect } from './IntellectualProperty.styled';
import styled from 'styled-components';
import {
  getCardsData,
  cardImageUrlGeneration,
  getFilteredCardsData,
} from '../../services/iPropertyService/iProperty.service';
import { calculatePaginationOptions } from '../../utils/pagination';

const CustomPaginationWrapper = styled.div`
  .ant-pagination-item {
    color: #a3a7c3;
  }
  .ant-pagination-item a {
    color: #a3a7c3;
  }
  .ant-pagination-item-active {
    background-color: #8589c1;
  }
  .ant-pagination-item-active a {
    color: #a3a7c3;
    color: white;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    color: #a3a7c3;
  }
  .ant-pagination-item:hover a,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #a3a7c3;
  }
  .ant-pagination-item-ellipsis {
    color: #a3a7c3 !important;
  }
`;

type ImageURLs = { [key: string]: { thumbnailURL: string; imageURL: string } };

const IntellectualProperty: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const previousPathRef = useRef<string>(location.pathname);
  const { Option } = Select;
  const { uitheme } = useSelector((state: RootState) => state.global);
  const isDarkMode = uitheme === 'dark';

  const { token } = theme.useToken();
  const [selectedPropertyData, setSelectedPropertyDataState] =
    useState<IPropertySingleCard | null>(null);

  // Redux state selection
  const {
    searchResults,
    searchTerm,
    currentPage,
    itemsPerPage,
    cardsData,
    filteredData,
  } = useSelector((state: RootState) => state.iProperty) || {};
  const [loading, setLoading] = useState(true);
  const { imageURLs } = useSelector((state: RootState) => state.iProperty);
  const [paginationOptions, setPaginationOptions] = useState<number[]>([]);

  const {
    userData: { publisherId },
  } = useSelector((state: RootState) => state.global);

  const contentStyle: React.CSSProperties = {
    color: isDarkMode ? '#ffffff' : '#0f0f1a',
    width: 'auto',
    backgroundColor: uitheme === 'dark' ? '#0F0F1A' : '#FCFCFC',
    padding: '61px 26px',
    borderRadius: '10px',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
    gap: '26px',
  };

  // Keep track of the previous path
  useEffect(() => {
    // Update previousPath reference on every route change
    const previousPath = previousPathRef.current;
    previousPathRef.current = location.pathname;

    // Only remove searchTerm from local storage and reset Redux when navigating away from `/ip`
    // but not when moving to a detail view (e.g., `/ip/some-id`)
    if (
      previousPath.startsWith('/ip') &&
      !location.pathname.startsWith('/ip')
    ) {
      console.log(
        'Navigating away from /ip, clearing searchTerm from localStorage and Redux store'
      );
      localStorage.removeItem('searchTerm');
      dispatch(setSearchTerm('')); // Clear the searchTerm from Redux store
      dispatch(setFilteredIPCardsData([])); // Clear the filtered IP cards data from Redux store
    }
  }, [location.pathname, dispatch]);

  // Additionally, ensure that searchTerm gets cleared when the component unmounts entirely
  useEffect(() => {
    return () => {
      // Check that we are navigating away entirely and not to a detailed IP path
      if (!location.pathname.startsWith('/ip')) {
        console.log(
          'Component unmounted, removing searchTerm from localStorage'
        );
        localStorage.removeItem('searchTerm');
        dispatch(setSearchTerm(''));
      }
    };
  }, [dispatch]);

  const isLatin = (str: string) => /^[A-Za-z]/.test(str.charAt(0));

  const sortCardsData = (data: IPropertyCard[]) => {
    return data.sort((a, b) => {
      const isLatinA = isLatin(a.card.title);
      const isLatinB = isLatin(b.card.title);

      if (isLatinA && !isLatinB) return -1;
      if (!isLatinA && isLatinB) return 1;
      return a.card.title.localeCompare(b.card.title);
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get('filter');
    const queryParams = new URLSearchParams();

    queryParams.append('publisherId', publisherId);
    if (filter) {
      queryParams.append('tags', filter);
    }

    const queryString = queryParams.toString();

    const fetchCards = async () => {
      try {
        let data: IPropertyCard[] = [];
        if (filter) {
          data = await getFilteredCardsData(queryString);
          data = sortCardsData(data);
          dispatch(setFilteredIPCardsData(data));
        } else if (!cardsData || cardsData.length === 0) {
          data = await getCardsData();
          data = sortCardsData(data);
          dispatch(setIPCardsData(data));
        }

        if (searchTerm) {
          console.log('Searching with term:', searchTerm);
          dispatch(searchIPropertyData(searchTerm));
        } else {
          fetchImages(data);
        }
      } catch (error: any) {
        notification.error({
          message: 'Request failed',
          description: error.message || error.toString(),
        });
      } finally {
        setLoading(false);
      }
    };

    dispatch(setCurrentPage(1));
    fetchCards();

    return () => {
      dispatch(setFilteredIPCardsData([]));
    };
  }, [dispatch, location.search, searchTerm, cardsData.length]);

  useEffect(() => {
    dispatch(
      setPageInfo({
        currentPageName: 'IP management',
        numberOfElem:
          searchResults.results.length > 0
            ? searchResults.results.length
            : (filteredData || cardsData).length,
      })
    );
  }, [dispatch, searchResults.results.length, cardsData.length, filteredData]);

  // Set Pagination Options
  useEffect(() => {
    const totalItems = searchResults.results.length
      ? searchResults.results.length
      : filteredData.length || cardsData.length;

    if (totalItems > 0) {
      const options = calculatePaginationOptions(totalItems);
      setPaginationOptions(options);
    }
  }, [searchResults.results.length, filteredData.length, cardsData.length]);

  const handleSearch = (searchTerm: string) => {
    localStorage.setItem('searchTerm', searchTerm);
    console.log('Setting searchTerm in localStorage:', searchTerm);
    dispatch(setSearchTerm(searchTerm));
    dispatch(searchIPropertyData(searchTerm));
  };

  const fetchImages = async (data: IPropertyCard[]) => {
    const urls: ImageURLs = {};

    await Promise.all(
      data.map(async (card) => {
        if (card.card.thumbnail) {
          try {
            const response = await cardImageUrlGeneration(card.card.thumbnail);
            urls[card.ipMetadataGuid] = {
              imageURL: response.imageURL || '',
              thumbnailURL: response.thumbnailURL || '',
            };
          } catch (error) {
            notification.error({
              message: 'Image Fetch Failed',
              description: `Could not fetch images for ${card.card.title}. Please try again later.`,
            });
          }
        }
      })
    );

    dispatch(setImageURLs(urls));
  };

  const navigateToPropertyDetails = (property: IPropertySingleCard) => {
    setSelectedPropertyDataState(property);
    dispatch(setSelectedPropertyData(property));
    localStorage.setItem('selectedPropertyData', JSON.stringify(property));
    navigate(`/ip/${property.ipMetadataGuid}`);
  };

  const handlePageChange: PaginationProps['onChange'] = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPageChange = (value: number) => {
    dispatch(setItemsPerPage(value));
  };

  const dataToDisplay = searchTerm.trim()
    ? searchResults.results
    : filteredData.length
    ? filteredData
    : filteredData && cardsData;

  const startIndex = Math.max((currentPage - 1) * itemsPerPage, 0);
  const paginatedCards = dataToDisplay.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <>
      <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        {!selectedPropertyData && (
          <SearchComponent
            onSearch={handleSearch}
            isDarkMode={isDarkMode}
            searchTerm={searchTerm}
            loading={loading}
          />
        )}
        <div style={{ fontWeight: 'bold', color: '#A3A7C3' }}>
          Display per page:
        </div>
        <StyledSelect
          size='large'
          value={itemsPerPage}
          style={{ width: '100px' }}
          onChange={(value: any) => handleItemsPerPageChange(value)}
          disabled={loading}
        >
          {paginationOptions.length > 0 &&
            paginationOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
        </StyledSelect>

        <Button
          onClick={() => navigate('/ip/insights')}
          disabled
          style={{
            borderRadius: '29px',
            height: '40px',
            width: '100px',
            padding: '10px 17px',
            borderColor: '#cacac',
            color: loading ? 'gray' : '#6C729D',
            fontSize: '16px',
            fontFamily: 'Futura',
            marginLeft: 'auto',
            backgroundColor: isDarkMode ? '#0f0f1a' : '#ffffff',
            border: isDarkMode ? '1px solid #656A92' : '#cacac',
          }}
        >
          Insights
        </Button>
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <>
          <div style={contentStyle}>
            {paginatedCards.length > 0 ? (
              paginatedCards.map((card: any, index) => (
                <StyledCard
                  key={index}
                  isDarkMode={isDarkMode}
                  property={card}
                  thumbnailURL={
                    imageURLs[card.ipMetadataGuid as string]?.thumbnailURL ||
                    null
                  }
                  navigateToPropertyDetails={() =>
                    navigateToPropertyDetails(card)
                  }
                />
              ))
            ) : (
              <p>No results found.</p>
            )}
          </div>

          {dataToDisplay.length > itemsPerPage && (
            <CustomPaginationWrapper>
              <Pagination
                current={currentPage}
                total={dataToDisplay.length}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
                style={{ marginBottom: '20px' }}
              />
            </CustomPaginationWrapper>
          )}
        </>
      )}
    </>
  );
};

export default IntellectualProperty;
