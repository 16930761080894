import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Card, Spin, Empty } from 'antd';

interface BarChartProps {
  isVertical: boolean;
  iscomp: boolean;
  isInv: boolean;
  data: any;
  title: string;
  loading: boolean;
  isApplications: boolean;
}

const BarChart = ({
  isVertical,
  iscomp,
  isInv,
  data,
  title,
  loading,
  isApplications,
}: BarChartProps) => {
  const aggregateData = (data: any[]) => {
    return data.reduce((acc, item) => {
      const existing = acc.find((i: any) => i.groupName === item.groupName);
      if (existing) {
        existing.count += item.count;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, [] as { groupName: string; count: number }[]);
  };

  const aggregatedData = aggregateData(data);
  const sortedData =
    title !== 'Applications over time'
      ? aggregatedData.sort(
          (a: { count: number }, b: { count: number }) => b.count - a.count
        )
      : aggregatedData;

  const customTooltip = ({ indexValue, value }: any) => (
    <div
      style={{
        padding: '5px 10px',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '3px',
        color: '#000',
      }}
    >
      {indexValue}: <strong>{value} cases</strong>
    </div>
  );

  return (
    <Card
      title={title}
      style={{ width: '500px', height: 'auto' }}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          height: '400px',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Spin size='large' />
        ) : data.length === 0 ? (
          <Empty description='No data to display' />
        ) : (
          <ResponsiveBar
            data={sortedData}
            keys={['count']}
            colors={isApplications ? '#66C2A5' : { scheme: 'set2' }}
            indexBy='groupName'
            margin={{ top: 20, right: 30, bottom: 50, left: 100 }}
            padding={0.3}
            layout={isVertical ? 'vertical' : 'horizontal'}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colorBy='indexValue'
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: -90,
              legendPosition: 'middle',
              legendOffset: 37,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: iscomp || isInv ? -80 : -60,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            animate={true}
            tooltip={customTooltip}
          />
        )}
      </div>
    </Card>
  );
};

export default BarChart;
