import authHeader from '../authenticationService/auth-header';

export const getBatchCostsData = async () => {
  try {
    const response = await fetch('https://api.ipmanagement.io/cost', {
      headers: authHeader(),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching user data: ' + error.message);
  }
};

export const fetchCostsPerIp = async (guid: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/cost/per-ip/${guid}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching image data: ' + error.message);
  }
};
