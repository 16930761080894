import styled from 'styled-components';
import { Button, Menu, MenuProps } from 'antd';

interface StyledMenuItemProps extends MenuProps {
  collapsed?: boolean;
  isDarkMode?: boolean;
}

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 150px;
`;

export const UserAvatarButton = styled(Button)<StyledMenuItemProps>`
  transition: background 0.3s, color 0.3s;
  background: ${(props) => (props.isDarkMode ? 'gray' : '#D9D9D9')};
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-right: 30px;
`;

export const UserName = styled.span<StyledMenuItemProps>`
  font-family: 'Overpass', sans-serif;
  margin-right: 8px;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'gray')};
  text-align: left;
`;

export const SettingsText = styled.span<StyledMenuItemProps>`
  font-family: 'Overpass', sans-serif;
  line-height: 14px;
  margin-right: 5px;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'gray')};
`;

export const UserInfo = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Noto Serif TC;
  color: #565656;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  line-height: 14px;
`;

export const UserEmail = styled.div`
  font-size: 14px;
  color: #666;
`;

export const DropdownButton = styled(Button)`
  border: none;
  background: transparent;
  margin: auto;
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const StyledMenuItem = styled(Menu.Item)<StyledMenuItemProps>`
  && {
    transition: background 0.3s, color 0.3s;
    background: ${({ isDarkMode }) => (isDarkMode ? '#313349' : 'white')};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 82px;
    margin: 0;
    border-radius: 0;
    width: auto;
    justify-content: center;
    gap: 5px;
  }

  &:hover,
  &:active,
  &:focus {
    background: #313349;
  }

  &.ant-menu-item-selected {
    background: ${({ isDarkMode }) => (isDarkMode ? 'none' : '#00a787')};
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    .ant-menu-title-content {
      font-weight: 700;
      display: ${({ collapsed }) =>
        collapsed ? 'none' : 'inline-block'} !important;
      margin: 0 !important;
      font-family: 'Futura';
      color: ${({ isDarkMode }) => (isDarkMode ? '#00a787' : '#FFFFFF')};
      font-size: 16px;
      line-height: 24px;
    }
  }

  /* Styles for the non-selected state */
  .ant-menu-title-content {
    display: ${({ collapsed }) =>
      collapsed ? 'none' : 'inline-block'} !important;
    margin: 0 !important;
    font-family: 'Futura';
    color: #6c729d;
    font-size: 16px;
    line-height: 24px;
  }
`;
