import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import { Card, Button } from 'antd';
import GeoJSON from '../../testJson/GeoJSON.json'; // Adjust the path to your GeoJSON file

const ChoroplethMap = () => {
  // Zoom state
  const [zoomLevel, setZoomLevel] = useState(1); // Initial zoom level
  const [center] = useState<[number, number]>([0, 20]); // Fixed center state

  const data = [
    { id: 'USA', value: 150 },
    { id: 'DEU', value: 120 },
    { id: 'FRA', value: 100 },
    { id: 'JPN', value: 90 },
    { id: 'GBR', value: 80 },
    { id: 'CHN', value: 70 },
    { id: 'IND', value: 60 },
    { id: 'RUS', value: 50 },
    { id: 'BRA', value: 40 },
  ];

  // Increase zoom level
  const handleZoomIn = () => {
    if (zoomLevel >= 4) return; // Limit max zoom level
    setZoomLevel(zoomLevel * 1.5);
  };

  // Decrease zoom level
  const handleZoomOut = () => {
    if (zoomLevel <= 1) return; // Limit min zoom level
    setZoomLevel(zoomLevel / 1.5);
  };

  return (
    <Card
      title='IP assets'
      style={{
        width: '88%',
        minWidth: '500px',
        height: 'auto',
        position: 'relative',
      }}
      bodyStyle={{ padding: 0 }}
    >
      {/* Zoom Controls Positioned in Bottom Left Corner */}
      <div
        style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <Button
          onClick={handleZoomIn}
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            fontSize: '18px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
          }}
        >
          +
        </Button>
        <Button
          onClick={handleZoomOut}
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            fontSize: '18px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
          }}
        >
          -
        </Button>
      </div>

      {/* Choropleth Map with Zoom and Mouse Panning */}
      <div style={{ height: 'auto', width: '100%' }}>
        <ComposableMap projection='geoMercator' width={1000} height={500}>
          <ZoomableGroup center={center} zoom={zoomLevel}>
            <Geographies geography={GeoJSON}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const countryData = data.find((d) => d.id === geo.id);
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        console.log(`Mouse entered: ${geo.properties.name}`);
                      }}
                      onMouseLeave={() => {
                        console.log(`Mouse left: ${geo.properties.name}`);
                      }}
                      onClick={() => {
                        console.log(`Clicked on: ${geo.properties.name}`);
                      }}
                      style={{
                        default: {
                          fill: countryData ? '#69b3a2' : '#E0E0E0', // Highlight country based on data
                          outline: 'none',
                        },
                        hover: {
                          fill: '#F53',
                          outline: 'none',
                        },
                        pressed: {
                          fill: '#E42',
                          outline: 'none',
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </Card>
  );
};

export default ChoroplethMap;
