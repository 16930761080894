import authHeader from '../authenticationService/auth-header';

interface DownloadFileParams {
  fileName: string;
}

export const getAllFilesData = async () => {
  try {
    const response = await fetch('https://api.ipmanagement.io/files', {
      headers: authHeader(),
    });
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }
    return response.json();
  } catch (error: any) {
    // throw new Error('Error fetching files data: ' + error.message);
  }
};

export const getSingleFileData = async (fileName: string) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/files?id=${fileName}`,
      {
        headers: authHeader(),
      }
    );
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching files data: ' + error.message);
  }
};

export const downloadFile = async (fileName: string): Promise<Response> => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/files/download?fileName=${fileName}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error: any) {
    throw new Error('Error fetching file: ' + error.message);
  }
};

export const markAsRead = async (guid: string) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/files/${guid}/markAsRead`,
      {
        method: 'POST',
        headers: authHeader(),
      }
    );
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }
    return response;
  } catch (error: any) {
    throw new Error('Error fetching files data: ' + error.message);
  }
};

export const markAllAsRead = async () => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/files/markAllAsRead`,
      {
        method: 'POST',
        headers: authHeader(),
      }
    );
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }
    return response;
  } catch (error: any) {
    throw new Error('Error fetching files data: ' + error.message);
  }
};
